import axios from 'axios';
import authHeader from "./auth-header";

const apiUrl = 'https://zl-api.mma.unixstorm.eu/admin/interview/questions';

export default {

    getList() {
        return axios.get(apiUrl, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    getSingle(interviewQuestionId) {
        return axios.get(apiUrl + "/" + interviewQuestionId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    create(interviewQuestionData) {
        return axios.post(apiUrl, {
            type:           interviewQuestionData.type,
            question:       interviewQuestionData.question,
            isRequired:     interviewQuestionData.isRequired
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    update(interviewQuestionId, interviewQuestionData) {
        return axios.put(apiUrl + "/" + interviewQuestionId, {
            type:           interviewQuestionData.type,
            question:       interviewQuestionData.question,
            isRequired:     interviewQuestionData.isRequired
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    delete(interviewQuestionId) {
        return axios.delete(apiUrl + "/" + interviewQuestionId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    updateInterviewQuestionsSort(interviewQuestions) {
        return axios.post(apiUrl + '/sort', {
            interviewQuestions: interviewQuestions
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    }
}