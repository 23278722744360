import axios from 'axios';
import authHeader from "./auth-header";

const apiUrl = 'https://zl-api.mma.unixstorm.eu/admin/interview/questions';

export default {

    getSingle(interviewQuestionId, interviewAnswerId) {
        return axios.get(apiUrl + "/" + interviewQuestionId + "/answers/" + interviewAnswerId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    create(interviewAnswerData, interviewQuestionId) {
        return axios.post(apiUrl + '/' + interviewQuestionId + '/answers', {
            answer:                 interviewAnswerData.answer
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    update(interviewQuestionId, interviewAnswerId, interviewAnswerData) {
        return axios.put(apiUrl + '/' + interviewQuestionId + '/answers/' + interviewAnswerId, {
            answer:     interviewAnswerData.answer
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    delete(interviewQuestionId, interviewAnswerId) {
        return axios.delete(apiUrl + '/' + interviewQuestionId + '/answers/' + interviewAnswerId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

}