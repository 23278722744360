<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar flat dark color="primary" style="border-radius: 0; position: sticky; top: 0;">
        <v-toolbar-title>Formularz wywiadu</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="d-flex justify-center py-12" v-if="loadingInterviewQuestions">
        <v-progress-circular
            :size="50"
            indeterminate
            color="#000080"
        ></v-progress-circular>
      </div>
      <div class="py-12" else>
        <v-container>
          <v-row justify="center" v-for="(interviewQuestion, index) in interviewQuestionsList" :key="interviewQuestion.id">
            <v-col cols="12" sm="10" md="8">
              <h2 class="body-1 font-weight-medium mt-0 mb-2">{{ index + 1 }}. {{ interviewQuestion.question }} <span v-if="interviewQuestion.isRequired == 1" class="red--text">*</span></h2>

              <div v-if="interviewQuestion.type == 'input'">
                <v-text-field
                    hide-details="true"
                    outlined
                ></v-text-field>
              </div>

              <div v-if="interviewQuestion.type == 'textarea'">
                <v-textarea
                    hide-details="true"
                    outlined
                ></v-textarea>
              </div>

              <div v-if="interviewQuestion.type == 'select'">
                <v-select
                    :items="interviewQuestion.interviewAnswers"
                    item-text="answer"
                    item-value="id"
                    hide-details="true"
                    outlined
                ></v-select>
              </div>

              <div v-if="interviewQuestion.type == 'radio'">
                <v-radio-group hide-details="true" class="mt-0">
                  <v-radio
                      v-for="(interviewAnswer, index) in interviewQuestion.interviewAnswers" :key="interviewAnswer.id"
                      :label="interviewAnswer.answer"
                      :value="interviewAnswer.answer"
                      :class="{ 'mt-0' : index == 0, 'mt-2' : index > 0 }"
                  ></v-radio>
                </v-radio-group>
              </div>

              <div v-if="interviewQuestion.type == 'checkbox'">
                <v-checkbox
                    v-for="(interviewAnswer, index) in interviewQuestion.interviewAnswers" :key="interviewAnswer.id"
                    :label="interviewAnswer.answer"
                    :value="interviewAnswer.answer"
                    hide-details="true"
                    :class="{ 'mt-0' : index == 0, 'mt-3' : index > 0 }"
                ></v-checkbox>
              </div>

              <div v-if="interviewQuestion.type == 'file'">
                <v-file-input
                    hide-details="true"
                    prepend-icon
                    prepend-inner-icon="$file"
                    outlined
                    placeholder="Wybierz plik"
                ></v-file-input>
              </div>

            </v-col>
          </v-row>
        </v-container>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
  import interviewQuestionService from "@/services/admin.interviewQuestion.service";

  export default {
    name: 'InterviewPreviewDialog',

    props: [ 'previewInterviewQuestionsList' ],

    data: () => ({
      dialog: true,
      interviewQuestionsList: null,
      loadingInterviewQuestions: false
    }),

    created() {
      const htmlTag = document.documentElement;
      htmlTag.classList.add("overflow-y-hidden");

      if (this.previewInterviewQuestionsList) {
        this.interviewQuestionsList = this.previewInterviewQuestionsList;
      } else {
        this.loadingInterviewQuestions = true;
        this.getInterviewQuestions();
      }
    },

    methods: {
      getInterviewQuestions() {
        this.loadingInterviewQuestions = true;

        interviewQuestionService.getList().then(data => {
          if (data.success) {
            this.interviewQuestionsList = data.data.interviewQuestions;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingInterviewQuestions = false;
        });
      },

      closeDialog() {
        const htmlTag = document.documentElement;
        htmlTag.classList.remove("overflow-y-hidden");

        this.dialog = false;
        this.$router.push({ name: 'adminInterview' })
      }
    }
  }
</script>