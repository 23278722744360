import axios from 'axios';
import authHeader from "./auth-header";

const apiUrl = 'https://zl-api.mma.unixstorm.eu/admin/discounts';

export default {

    getList() {
        return axios.get(apiUrl, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    getSingle(discountId) {
        return axios.get(apiUrl + "/" + discountId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    create(discountData) {
        return axios.post(apiUrl, {
            basic:              discountData.basic,
            standard:           discountData.standard,
            premium:            discountData.premium,
            code:               discountData.code,
            discount:           discountData.discount,
            discountType:       discountData.discountType,
            limitedQuantity:    discountData.limitedQuantity,
            quantity:           discountData.quantity,
            limitedTime:        discountData.limitedTime,
            date:               discountData.date
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    update(discountId, discountData) {
        return axios.put(apiUrl + "/" + discountId, {
            basic:              discountData.basic,
            standard:           discountData.standard,
            premium:            discountData.premium,
            code:               discountData.code,
            discount:           discountData.discount,
            discountType:       discountData.discountType,
            limitedQuantity:    discountData.limitedQuantity,
            quantity:           discountData.quantity,
            limitedTime:        discountData.limitedTime,
            date:               discountData.date
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    delete(discountId) {
        return axios.delete(apiUrl + '/' + discountId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

}