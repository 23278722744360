<template>

  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title class="justify-space-between pa-6">
            <span class="headline">Lista zaproszonych użytkowników</span>
            <!-- todo :disabled="loadingDiscounts" -->
            <v-btn large class="elevation-0 white--text font-weight-medium" color="#008000" to="/agent/invitations/new">Wyślij zaproszenie</v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <router-view name="agentInvitationDialog" v-on:dialog-callback="console.log('asd')"></router-view>
  </v-container>

</template>

<script>
  export default {
    name: 'AgentInvitations',

    data: () => ({

    }),

  };
</script>

<style>

</style>