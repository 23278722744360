import axios from 'axios';
import authHeader from "./auth-header";

const apiUrl = 'https://zl-api.mma.unixstorm.eu/admin/users';

export default {

    getList() {
        return axios.get(apiUrl, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    getSingle(userId) {
        return axios.get(apiUrl + "/" + userId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    create(userData) {
        return axios.post(apiUrl, {
            email:              userData.email,
            password:           userData.password,
            role:               userData.role,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    update(userId, userData) {
        return axios.put(apiUrl + "/" + userId, {
            email:              userData.email,
            password:           userData.password,
            role:               userData.role
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    delete(userId) {
        return axios.delete(apiUrl + "/" + userId, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

}