<template>

  <v-container fluid fill-height>
    <v-row justify="center">
      <v-col cols="12" class="d-flex justify-center">
        <v-progress-circular
            :size="50"
            indeterminate
            color="#000080"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Start',

    data: () => ({

    }),

    created() {
      const token = sessionStorage.getItem('token');

      if (token) {
        this.checkToken();
      } else {
        this.$router.push({ name: 'login' });
      }
    },

    methods: {
      checkToken() {
        // TODO
        this.$router.push({ name: 'adminUsers' });
      }
    }

  };
</script>

<style>

</style>