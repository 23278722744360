import axios from 'axios';
import authHeader from "./auth-header";

const apiUrl = 'https://zl-api.mma.unixstorm.eu/admin/packages';

export default {

    getList() {
        return axios.get(apiUrl, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

    update(packagesData) {
        return axios.post(apiUrl + "/update", {
            basicPrice:     packagesData.basicPrice,
            standardPrice:  packagesData.standardPrice,
            premiumPrice:   packagesData.premiumPrice,
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                snackbar: { 'color': 'red' },
                message: 'Błąd połączenia z API.'
            };

            return response;
        });
    }

}