<template>

  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          DASHBOARD
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
  export default {
    name: 'AgentDashboard',

    data: () => ({

    }),

  };
</script>

<style>

</style>