<template>

  <v-container fluid>
    <div class="header mb-6">
      <v-row align="center">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="overline pa-0"></v-breadcrumbs>
          <h1 class="header__title font-weight-medium mt-0 mb-0">Ceny pakietów</h1>
        </v-col>
      </v-row>
    </div>

    <v-form @submit.prevent="submitForm">
      <v-card elevation="0">
        <v-card-text class="pa-0">
          <div class="package pa-6">
            <v-row align="center">
              <v-col>
                <h2 class="body-1 font-weight-medium my-0">Basic</h2>
              </v-col>
              <v-col cols="auto">
                <v-text-field
                    label="Cena"
                    v-model="form.basicPrice"
                    :disabled="loadingForm"
                    :error-messages="formBasicPriceErrors"
                    hide-details="auto"
                    suffix="PLN"
                    outlined
                    @input="$v.form.basicPrice.$touch(), removeApiError('basicPrice')"
                    @blur="$v.form.basicPrice.$touch(), removeApiError('basicPrice')"
                    class="input--price"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div class="package pa-6">
            <v-row align="center">
              <v-col>
                <h2 class="body-1 font-weight-medium my-0">Standard</h2>
              </v-col>
              <v-col cols="auto">
                <v-text-field
                    label="Cena"
                    v-model="form.standardPrice"
                    :disabled="loadingForm"
                    :error-messages="formStandardPriceErrors"
                    hide-details="auto"
                    suffix="PLN"
                    outlined
                    @input="$v.form.standardPrice.$touch(), removeApiError('standardPrice')"
                    @blur="$v.form.standardPrice.$touch(), removeApiError('standardPrice')"
                    class="input--price"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div class="package pa-6">
            <v-row align="center">
              <v-col>
                <h2 class="body-1 font-weight-medium my-0">Premium</h2>
              </v-col>
              <v-col cols="auto">
                <v-text-field
                    label="Cena"
                    v-model="form.premiumPrice"
                    :disabled="loadingForm"
                    :error-messages="formPremiumPriceErrors"
                    hide-details="auto"
                    suffix="PLN"
                    outlined
                    @input="$v.form.premiumPrice.$touch(), removeApiError('premiumPrice')"
                    @blur="$v.form.premiumPrice.$touch(), removeApiError('premiumPrice')"
                    class="input--price"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions class="px-6 pt-0 pb-6">
          <v-spacer></v-spacer>
          <v-btn type="submit" class="elevation-0 white--text font-weight-medium px-5" color="#008000" large :disabled="loadingForm || loadingPackages" :loading="loadingForm">Zapisz zmiany</v-btn>
        </v-card-actions>

        <v-overlay absolute :value="loadingPackages" color="#ffffff">
          <v-progress-circular
              indeterminate
              color="primary"
              size="46"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-form>
  </v-container>

</template>

<script>
  import { validationMixin } from "vuelidate";
  import { required, integer } from "vuelidate/lib/validators";
  import packageService from "@/services/admin.package.service";

  export default {
    name: 'AdminPackages',

    mixins: [validationMixin],

    validations: {
      form: {
        basicPrice: {
          required,
          integer
        },
        standardPrice: {
          required,
          integer
        },
        premiumPrice: {
          required,
          integer
        }
      }
    },

    data: () => ({
      breadcrumbsItems: [
        { text: 'Pakiety' }
      ],
      form: {
        basicPrice: '',
        standardPrice: '',
        premiumPrice: ''
      },
      loadingPackages: true,
      loadingForm: false
    }),

    computed: {
      formBasicPriceErrors() {
        const errors = [];
        if (!this.$v.form.basicPrice.$dirty) return errors;
        !this.$v.form.basicPrice.required && errors.push("Musisz uzupełnić to pole.");
        !this.$v.form.basicPrice.integer && errors.push("Nieprawidłowa wartość.");
        if (this.apiErrors && this.apiErrors.basicPrice) errors.push(this.apiErrors.basicPrice);
        return errors;
      },
      formStandardPriceErrors() {
        const errors = [];
        if (!this.$v.form.standardPrice.$dirty) return errors;
        !this.$v.form.standardPrice.required && errors.push("Musisz uzupełnić to pole.");
        !this.$v.form.standardPrice.integer && errors.push("Nieprawidłowa wartość.");
        if (this.apiErrors && this.apiErrors.standardPrice) errors.push(this.apiErrors.standardPrice);
        return errors;
      },
      formPremiumPriceErrors() {
        const errors = [];
        if (!this.$v.form.premiumPrice.$dirty) return errors;
        !this.$v.form.premiumPrice.required && errors.push("Musisz uzupełnić to pole.");
        !this.$v.form.premiumPrice.integer && errors.push("Nieprawidłowa wartość.");
        if (this.apiErrors && this.apiErrors.premiumPrice) errors.push(this.apiErrors.premiumPrice);
        return errors;
      }
    },

    created() {
      this.getPackages();
    },

    methods: {
      getPackages() {
        this.loadingPackages = true;

        packageService.getList().then(data => {
          if (data.success) {
            this.form.basicPrice = data.data.packages[0].price;
            this.form.standardPrice = data.data.packages[1].price;
            this.form.premiumPrice = data.data.packages[2].price;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingPackages = false;
        });
      },

      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;

          this.updatePackages();
        }
      },

      updatePackages() {
        packageService.update(this.form).then(data => {
          if (data.success) {
            this.getPackages();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      }
    }
  }

</script>

<style>
.package { border-bottom: 1px solid #efefef; }
.package:last-child{ border-bottom: none; }
.input--price { width: 180px; }
.input--price .v-text-field__slot input { text-align: center }
</style>