<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <form @submit.prevent="submitForm">
      <v-card class="elevation-0">
        <v-card-title class="justify-space-between pa-6">
          <span class="headline">Nowe zaproszenie</span>
          <v-btn text icon :disabled="loadingForm" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-6 py-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="form.email"
                  :disabled="loadingForm"
                  :error-messages="formEmailErrors"
                  label="Adres e-mail"
                  hide-details="auto"
                  outlined
                  @input="$v.form.email.$touch(), removeApiError('email')"
                  @blur="$v.form.email.$touch(), removeApiError('email')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn type="button" class="elevation-0" large text :disabled="loadingForm" @click="closeDialog">Anuluj</v-btn>
          <v-btn type="submit" class="elevation-0 white--text font-weight-medium" color="#008000" large :disabled="loadingForm" :loading="loadingForm">Wyślij</v-btn>
        </v-card-actions>

        <!--<v-overlay absolute :value="loadingDiscount" color="#ffffff">
          <v-progress-circular
              indeterminate
              color="primary"
              size="46"
          ></v-progress-circular>
        </v-overlay>-->
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { email, required } from 'vuelidate/lib/validators';

  export default {
    name: 'InvitationDialog',

    mixins: [validationMixin],

    validations: {
      form: {
        email: {
          required,
          email
        }
      }
    },

    data: () => ({
      dialog: true,
      form: {
        email: ''
      },
      loadingForm: false,
      apiErrors: null,
    }),

    computed: {
      formEmailErrors() {
        const errors = [];
        if (!this.$v.form.email.$dirty) return errors;
        !this.$v.form.email.required && errors.push('Musisz uzupełnić to pole');
        !this.$v.form.email.email && errors.push('Adres e-mail jest nieprawidłowy');
        if (this.apiErrors && this.apiErrors.email) errors.push(this.apiErrors.email);
        return errors;
      },
    },

    created() {

    },

    methods: {
      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;
        }
      },

      closeDialog() {
        this.dialog = false;
        this.$router.push({ name: 'agentInvitations' })
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      }
    }
  }
</script>