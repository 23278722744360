import Start from '../views/Start';

import Login from '../views/Login';
import LoginAuth from '../views/LoginAuth';
import ForgotPassword from '../views/ForgotPassword';
import ResetPassword from '../views/ResetPassword';
import SignUp from '../views/SignUp';

import UserOrder from '../views/user/Order';

import UserDashboard from '../views/user/Dashboard';

import AdminUsers from '../views/admin/Users';
import AdminUserDialog from '../components/admin/UserDialog';

import AdminInterview from '../views/admin/Interview';
import AdminInterviewQuestionDialog from '../components/admin/InterviewQuestionDialog';
import AdminInterviewAnswerDialog from '../components/admin/InterviewAnswerDialog';
import InterviewPreviewDialog from '../components/admin/InterviewPreviewDialog';

import AdminDiscounts from '../views/admin/Discounts';
import AdminDiscountDialog from '../components/admin/DiscountDialog';

import AdminPackages from '../views/admin/Packages';

import DeleteDialog from '../components/DeleteDialog';

import AgentDashboard from '../views/agent/Dashboard';

import AgentInvitations from '../views/agent/Invitations';
import AgentInvitationDialog from '../components/agent/InvitationDialog.vue';

export default [

    // ======== Default ========
    {
        path: "/",
        component: Start,
        name: "start"
    },

    // ======== Account ========
    // Login
    {
        path: '/login',
        component: Login,
        name: 'login'
    },

    {
        path: '/login/auth',
        component: LoginAuth,
        name: 'loginAuth'
    },

    // Forgot password
    {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgotPassword'
    },

    // Reset password
    {
        path: '/reset-password/:resetPasswordCode',
        component: ResetPassword,
        name: 'resetPassword'
    },

    // Sign up
    {
        path: '/signup',
        component: SignUp,
        name: 'signup'
    },

    // ======== Admin Users ========
    // List
    {
        path: '/admin/users',
        component: AdminUsers,
        name: 'adminUsers',
        children: [
            // Create
            {
                path: 'create',
                components: {
                    adminUserDialog: AdminUserDialog
                },
                name: 'adminUserCreate'
            },
            // Edit
            {
                path: ':userId/edit',
                components: {
                    adminUserDialog: AdminUserDialog
                },
                name: 'adminUserEdit'
            },
            // Delete
            // {
            //     path: ':userId/delete',
            //     components: {
            //         deleteDialog: DeleteDialog
            //     },
            //     name: 'adminUserDelete'
            // }
        ]
    },

    // ======== Admin Interview ========
    // Questions list
    {
        path: '/admin/interview',
        component: AdminInterview,
        name: 'adminInterview',
        children: [
            // Create question
            {
                path: 'questions/create',
                components: {
                    adminInterviewQuestionDialog: AdminInterviewQuestionDialog
                },
                name: 'adminInterviewQuestionCreate'
            },
            // Edit question
            {
                path: 'questions/:interviewQuestionId/edit',
                components: {
                    adminInterviewQuestionDialog: AdminInterviewQuestionDialog
                },
                name: 'adminInterviewQuestionEdit'
            },
            // Delete question
            {
                path: 'questions/:interviewQuestionId/delete',
                components: {
                    deleteDialog: DeleteDialog
                },
                name: 'adminInterviewQuestionDelete'
            },
            // Create answer
            {
                path: 'questions/:interviewQuestionId/answers/create',
                components: {
                    adminInterviewAnswerDialog: AdminInterviewAnswerDialog
                },
                name: 'adminInterviewAnswerCreate'
            },
            // Edit answer
            {
                path: 'questions/:interviewQuestionId/answers/:interviewAnswerId/edit',
                components: {
                    adminInterviewAnswerDialog: AdminInterviewAnswerDialog
                },
                name: 'adminInterviewAnswerEdit'
            },
            // Delete answer
            {
                path: 'questions/:interviewQuestionId/answers/:interviewAnswerId/delete',
                components: {
                    deleteDialog: DeleteDialog
                },
                name: 'adminInterviewAnswerDelete'
            },
            // Preview
            {
                path: 'preview',
                components: {
                    adminInterviewPreviewDialog: InterviewPreviewDialog
                },
                name: 'adminInterviewPreview'
            },
        ]
    },

    // ======== Admin Discounts ========
    // Discounts list
    {
        path: '/admin/packages',
        component: AdminPackages,
        name: 'adminPackages',
    },

    // ======== Admin Discounts ========
    // Discounts list
    {
        path: '/admin/discounts',
        component: AdminDiscounts,
        name: 'adminDiscounts',
        children: [
            // Create
            {
                path: 'create',
                components: {
                    adminDiscountDialog: AdminDiscountDialog
                },
                name: 'adminDiscountCreate'
            },
            // Edit
            {
                path: ':discountId/edit',
                components: {
                    adminDiscountDialog: AdminDiscountDialog
                },
                name: 'adminDiscountEdit'
            },
            // Delete
            {
                path: ':discountId/delete',
                components: {
                    deleteDialog: DeleteDialog
                },
                name: 'adminDiscountDelete'
            },
        ]
    },

    // ======== User ========
    // Order
    {
        path: '/user/order',
        component: UserOrder,
        name: 'userOrder',
    },

    // Dashboard
    {
        path: '/user/dashboard',
        component: UserDashboard,
        name: 'userDashboard',
    },

    // ======== Agent ========
    // Dashboard
    {
        path: '/agent/dashboard',
        component: AgentDashboard,
        name: 'agentDashboard',
    },

    // Invitations
    {
        path: '/agent/invitations',
        component: AgentInvitations,
        name: 'agentInvitations',
        children: [
            // New invitation (send)
            {
                path: 'new',
                components: {
                    agentInvitationDialog: AgentInvitationDialog
                },
                name: 'agentInvitationNew'
            }
        ]
    },
];