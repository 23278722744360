<template>

  <v-container fluid>
    <div class="header mb-6">
      <v-row align="center">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="overline pa-0"></v-breadcrumbs>
          <h1 class="header__title font-weight-medium mt-0 mb-0">Lista kodów rabatowych</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn large class="elevation-0 white--text font-weight-medium" color="#008000" to="/admin/discounts/create" :disabled="loadingDiscounts">Dodaj rabat</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-card elevation="0">
      <v-card-title class="py-3 px-6">
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            label="Szukaj"
            solo
            clearable
            class="input--search"
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
            :headers="headers"
            :items="discountsList"
            :sort-desc="false"
            :loading="loadingDiscounts"
            :search="search"
            sort-by="id"
            class="table--datatable elevation-0"
            no-data-text="Nic nie znaleziono"
            no-results-text="Nie znaleziono wyników pasujących do kryteriów wyszukiwania"
            loading-text="Ładowanie..."
            :header-props="{
              sortByText: 'Sortuj po:'
            }"
            :footer-props="{
              itemsPerPageAllText: 'Wszystkie',
              itemsPerPageText: 'Ilość wyników na stronie'
            }"
        >
          <template v-slot:[`item.packages`]="{ item }">
            <v-chip class="ma-1" label small :class="{ 'primary white--text': item.basic == 1 }">B</v-chip>
            <v-chip class="ma-1" label small :class="{ 'primary white--text': item.standard == 1 }">S</v-chip>
            <v-chip class="ma-1" label small :class="{ 'primary white--text': item.premium == 1 }">P</v-chip>
          </template>
          <template v-slot:[`item.discount`]="{ item }">
            -{{ item.discount }}{{ item.discountType }}
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <!-- TODO: zamienić X, pokombinować z klasami  -->
            <span :class="{ 'red--text': item.quantity >= 10 }">{{ item.limitedQuantity == 1 ? 'X / ' + item.quantity : 'X' }}</span>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <span :class="{ 'red--text':  new Date(item.date) < new Date() }">{{ item.limitedTime == 1 ? item.date : '-' }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn elevation="0" class="white--text ma-1" color="#008000" @click="showDiscountDialog(item)">Edytuj</v-btn>
            <v-btn color="error" elevation="0" class="ma-1" @click="showDeleteDialog(item)">Usuń</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <router-view name="adminDiscountDialog" :discountData="discountData" v-on:close-dialog="closeDiscountDialog" v-on:dialog-callback="getDiscounts"></router-view>
    <router-view name="deleteDialog" :dialogData="deleteDialogData" :loading="loadingDeleteDialog" v-on:close-dialog="closeDeleteDialog" v-on:dialog-callback="deleteDiscount"></router-view>
  </v-container>

</template>

<script>
  import discountService from "@/services/admin.discount.service";

  export default {
    name: 'AdminUsers',

    data: () => ({
      breadcrumbsItems: [
        { text: 'Rabaty' }
      ],
      discountsList: [],
      search: '',
      headers: [
        { text: 'Kod', value: 'code' },
        { text: 'Pakiety', value: 'packages', sortable: false, filterable: false },
        { text: 'Rabat', value: 'discount', sortable: false, filterable: false },
        { text: 'Ilość użyć', value: 'quantity', sortable: false, filterable: false },
        { text: 'Data wygaśnięcia', value: 'date', sortable: false, filterable: false },
        { text: '', value: 'actions', align: 'end', sortable: false }
      ],
      loadingDiscounts: true,
      discountData: null,
      deleteDialogData: null,
      loadingDeleteDialog: false,
    }),

    created() {
      this.getDiscounts();
    },

    methods: {
      getDiscounts() {
        this.loadingDiscounts = true;

        discountService.getList().then(data => {
          if (data.success) {
            this.discountsList = data.data.discounts;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDiscounts = false;
        });
      },

      deleteDiscount() {
        this.loadingDeleteDialog = true;

        discountService.delete(this.deleteDialogData.discountId).then(data => {
          if (data.success) {
            this.closeDeleteDialog();
            this.getDiscounts();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDeleteDialog = false;
        });
      },

      showDiscountDialog(discount) {
        this.discountData = discount;
        this.$router.push({ name: 'adminDiscountEdit', params: { discountId: discount.id } });
      },

      closeDiscountDialog() {
        this.discountData = null;
      },

      showDeleteDialog(discount) {
        this.deleteDialogData = {
          discountId: discount.id,
          title: "Usuwanie rabatu",
          text: "Czy na pewno usunąć ten rabat?"
        };
        this.$router.push({ name: "adminDiscountDelete", params: { discountId: discount.id } });
      },

      closeDeleteDialog() {
        this.deleteDialogData = null;
        this.$router.push({ name: 'adminDiscounts' })
      },
    }
  }

</script>