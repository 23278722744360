<template>

  <v-container fluid>
    <div class="header mb-6">
      <v-row align="center">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="overline pa-0"></v-breadcrumbs>
          <h1 class="header__title font-weight-medium mt-0 mb-0">Lista pytań</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn large class="elevation-0 font-weight-medium mr-3" color="primary" to="/admin/interview/preview" :disabled="loadingInterviewQuestions">Podgląd</v-btn>
          <v-btn large class="elevation-0 white--text font-weight-medium" color="#008000" to="/admin/interview/questions/create" :disabled="loadingInterviewQuestions">Dodaj pytanie</v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="loadingInterviewQuestions">
      <v-card elevation="0">
        <v-card-text class="d-flex justify-center pa-6">
          <v-progress-circular
              :size="50"
              indeterminate
              color="#000080"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>

      <div class="position-relative" v-if="interviewQuestionsList.length > 0">
        <draggable v-model="interviewQuestionsList" handle=".handle" @end="handleDragEnd">
          <transition-group>
            <v-card elevation="0" class="mb-4" v-for="interviewQuestion in interviewQuestionsList" :key="interviewQuestion.id">
              <v-card-text class="pa-6">
                <div class="d-flex align-center justify-space-between">
                  <div>
                    <v-chip label small class="text-uppercase">
                      {{ interviewQuestion.type }}
                    </v-chip>
                    <h2 class="body-1 font-weight-medium mt-2 mb-0">{{ interviewQuestion.question }} <span v-if="interviewQuestion.isRequired == 1" class="red--text">*</span></h2>
                  </div>
                  <div class="ml-3">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" class="ma-1" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item link @click="showInterviewAnswerDialog(interviewQuestion, null)" :disabled="interviewQuestion.type == 'input' || interviewQuestion.type == 'textarea' || interviewQuestion.type == 'file'">
                          <v-list-item-title>Dodaj odpowiedź</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="showInterviewQuestionDialog(interviewQuestion)">
                          <v-list-item-title>Edytuj</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="red--text" link @click="showDeleteQuestionDialog(interviewQuestion)">
                          <v-list-item-title>Usuń</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-btn elevation="0" color="primary" class="handle cursor-move px-3 ma-1" style="min-width: 0">
                      <v-icon>mdi-cursor-move</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="answers mt-4" v-if="interviewQuestion.type !== 'input' && interviewQuestion.type !== 'textarea' && interviewQuestion.type !== 'file' && interviewQuestion.interviewAnswers.length > 0">
                  <div class="answer d-flex align-center justify-space-between pa-1" v-for="interviewAnswer in interviewQuestion.interviewAnswers" :key="interviewAnswer.id">
                    <p class="pl-1 my-0">{{ interviewAnswer.answer }}</p>
                    <div>
                      <v-btn elevation="0" color="#008000" class="white--text ma-1" small @click="showInterviewAnswerDialog(interviewQuestion, interviewAnswer)">Edytuj</v-btn>
                      <v-btn elevation="0" color="error" class="ma-1" small @click="showDeleteAnswerDialog(interviewQuestion, interviewAnswer)">Usuń</v-btn>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </transition-group>
        </draggable>
      </div>

      <v-card elevation="0" v-else>
        <v-card-text class="pa-6">
          <p class="text-center my-0">Nie dodano jeszcze żadnych pytań.</p>
        </v-card-text>
      </v-card>

    </div>

    <router-view name="adminInterviewQuestionDialog" :interviewQuestionData="interviewQuestionData" v-on:close-dialog="closeInterviewQuestionDialog" v-on:dialog-callback="getInterviewQuestions"></router-view>
    <router-view name="deleteDialog" :dialogData="deleteDialogData" :loading="loadingDeleteDialog" v-on:close-dialog="closeDeleteDialog" v-on:dialog-callback="deleteDialogCallback"></router-view>
    <router-view name="adminInterviewAnswerDialog" :interviewQuestionData="interviewQuestionData" :interviewAnswerData="interviewAnswerData" v-on:close-dialog="closeInterviewAnswerDialog" v-on:dialog-callback="getInterviewQuestions"></router-view>
    <router-view name="adminInterviewPreviewDialog" :previewInterviewQuestionsList="previewInterviewQuestionsList"></router-view>
  </v-container>

</template>

<script>

  import draggable from 'vuedraggable';
  import interviewQuestionService from "@/services/admin.interviewQuestion.service";
  import interviewAnswerService from "@/services/admin.interviewAnswer.service";

  export default {
    name: 'AdminInterview',

    components: {
      draggable
    },

    data: () => ({
      breadcrumbsItems: [
        { text: 'Formularz wywiadu' }
      ],
      interviewQuestionsList: [],
      previewInterviewQuestionsList: null,
      loadingInterviewQuestions: true,
      interviewQuestionData: null,
      deleteDialogData: null,
      loadingDeleteDialog: false,
      interviewAnswerData: null
    }),

    created() {
      this.getInterviewQuestions();
    },

    methods: {
      getInterviewQuestions() {
        this.loadingInterviewQuestions = true;

        interviewQuestionService.getList().then(data => {
          if (data.success) {
            this.interviewQuestionsList = data.data.interviewQuestions;
            this.previewInterviewQuestionsList = data.data.interviewQuestions;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingInterviewQuestions = false;
        });
      },

      showInterviewQuestionDialog(interviewQuestion) {
        this.interviewQuestionData = interviewQuestion;
        this.$router.push({ name: 'adminInterviewQuestionEdit', params: { interviewQuestionId: interviewQuestion.id } });
      },

      closeInterviewQuestionDialog() {
        this.interviewQuestionData = null;
      },

      showDeleteQuestionDialog(interviewQuestion) {
        this.deleteDialogData = {
          type: 'question',
          interviewQuestionId: interviewQuestion.id,
          title: "Usuwanie pytania",
          text: "Czy na pewno usunąć to pytanie?"
        };
        this.$router.push({ name: "adminInterviewQuestionDelete", params: { interviewQuestionId: interviewQuestion.id } });
      },

      showDeleteAnswerDialog(interviewQuestion, interviewAnswer) {
        this.deleteDialogData = {
          type: 'answer',
          interviewQuestionId: interviewQuestion.id,
          interviewAnswerId: interviewAnswer.id,
          title: "Usuwanie odpowiedzi",
          text: "Czy na pewno usunąć tą odpowiedź?"
        };
        this.$router.push({ name: "adminInterviewAnswerDelete", params: { interviewQuestionId: interviewQuestion.id, interviewAnswerId: interviewAnswer.id } });
      },

      closeDeleteDialog() {
        this.deleteDialogData = null;
        this.$router.push({ name: "adminInterview" })
      },

      deleteDialogCallback() {
        if (this.deleteDialogData.type == 'question') {
          this.deleteInterviewQuestion();
        } else {
          this.deleteInterviewAnswer();
        }
      },

      deleteInterviewQuestion() {
        this.loadingDeleteDialog = true;

        interviewQuestionService.delete(this.deleteDialogData.interviewQuestionId).then(data => {
          if (data.success) {
            this.closeDeleteDialog();
            this.getInterviewQuestions();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDeleteDialog = false;
        });
      },

      deleteInterviewAnswer() {
        this.loadingDeleteDialog = true;

        const interviewQuestionId = this.deleteDialogData.interviewQuestionId;
        const interviewAnswerId = this.deleteDialogData.interviewAnswerId;

        interviewAnswerService.delete(interviewQuestionId, interviewAnswerId).then(data => {
          if (data.success) {
            this.closeDeleteDialog();
            this.getInterviewQuestions();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDeleteDialog = false;
        });
      },

      handleDragEnd() {
        this.updateInterviewQuestionsSort();
      },

      updateInterviewQuestionsSort() {
        this.loadingInterviewQuestions = true;

        const newInterviewQuestionsList = this.interviewQuestionsList.map((item, index) => {
          item.sort = index + 1;
          return item;
        });

        interviewQuestionService.updateInterviewQuestionsSort(newInterviewQuestionsList).then(data => {
          if (data.success) {
            this.interviewQuestionsList = data.data.interviewQuestions;
            this.previewInterviewQuestionsList = data.data.interviewQuestions;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch("account/logout");
              this.$router.push({ name: "login" });
            }
          }

          if (data.snackbar) {
            this.$store.commit("snackbar/addObject", {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingInterviewQuestions = false;
        });
      },

      showInterviewAnswerDialog(interviewQuestion, interviewAnswer) {
        this.interviewQuestionData = interviewQuestion;
        this.interviewAnswerData = interviewAnswer;

        if (interviewAnswer) {
          this.$router.push({ name: 'adminInterviewAnswerEdit', params: { interviewQuestionId: interviewQuestion.id, interviewAnswerId: interviewAnswer.id } });
        } else {
          this.$router.push({ name: 'adminInterviewAnswerCreate', params: { interviewQuestionId: interviewQuestion.id } });
        }
      },

      closeInterviewAnswerDialog() {
        this.interviewAnswerData = null;
      }
    }
  }

</script>

<style>
.answer:hover { background-color: #f1f1f1; }
</style>