import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    // mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    let resetPasswordPath = "";

    if (to.name == "resetPassword") { resetPasswordPath = to.path; }

    const publicPages = ['/', '/login', '/login/auth', '/forgot-password', resetPasswordPath, '/signup', '/user/order'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = sessionStorage.getItem('token');

    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;