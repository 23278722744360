<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <form @submit.prevent="submitForm">
      <v-card class="elevation-0">
        <v-card-title class="justify-space-between pa-6">
          <span class="headline">Użytkownik</span>
          <v-btn text icon :disabled="loadingForm" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-6 py-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="form.email"
                  :disabled="loadingForm"
                  :error-messages="formEmailErrors"
                  label="E-mail"
                  hide-details="auto"
                  outlined
                  @input="$v.form.email.$touch(), removeApiError('email')"
                  @blur="$v.form.email.$touch(), removeApiError('email')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="form.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :disabled="loadingForm"
                  :error-messages="formPasswordErrors"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$route.params.userId ? 'Nowe hasło' : 'Hasło'"
                  hide-details="auto"
                  outlined
                  @input="$v.form.password.$touch(), removeApiError('password')"
                  @blur="$v.form.password.$touch(), removeApiError('password')"
                  @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                  :items="roles"
                  item-text="text"
                  item-value="value"
                  v-model="form.role"
                  :disabled="loadingForm"
                  :error-messages="formRoleErrors"
                  label="Rola"
                  hide-details="auto"
                  outlined
                  @change="$v.form.role.$touch(), removeApiError('role')"
                  @blur="$v.form.role.$touch(), removeApiError('role')"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn type="button" class="elevation-0" large text :disabled="loadingForm" @click="closeDialog">Anuluj</v-btn>
          <v-btn type="submit" class="elevation-0 white--text font-weight-medium" color="#008000" large :disabled="loadingForm" :loading="loadingForm">Zapisz</v-btn>
        </v-card-actions>

        <v-overlay absolute :value="loadingUser" color="#ffffff">
          <v-progress-circular
              indeterminate
              color="primary"
              size="46"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required, requiredIf, email } from 'vuelidate/lib/validators';
  import userService from '../../services/admin.user.service';

  export default {
    name: 'UserDialog',

    props: ['userData'],

    mixins: [validationMixin],

    validations: {
      form: {
        email: {
          required,
          email
        },
        password: { // todo: walidacja hasła
          required: requiredIf(function() {
            return !this.$route.params.userId
          })
        },
        role: {
          required
        }
      }
    },

    data: () => ({
      dialog: true,
      form: {
        email: '',
        password: '',
        role: null
      },
      roles: [
        { text: 'Użytkownik', value: 'user' },
        { text: 'Landlord', value: 'landlord' },
        { text: 'Agent', value: 'agent' },
        { text: 'Analityk', value: 'analyst' },
        { text: 'Admin', value: 'admin' }
      ],
      loadingUser: false,
      showPassword: false,
      loadingForm: false,
      apiErrors: null
    }),

    computed: {
      formEmailErrors() {
        const errors = [];
        if (!this.$v.form.email.$dirty) return errors;
        !this.$v.form.email.required && errors.push('Musisz uzupełnić to pole');
        !this.$v.form.email.email && errors.push('Adres e-mail jest nieprawidłowy');
        if (this.apiErrors && this.apiErrors.email) errors.push(this.apiErrors.email);
        return errors;
      },
      formPasswordErrors() {
        const errors = [];
        if (!this.$v.form.password.$dirty) return errors;
        !this.$v.form.password.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.password) errors.push(this.apiErrors.password);
        return errors;
      },
      formRoleErrors() {
        const errors = [];
        if (!this.$v.form.role.$dirty) return errors;
        !this.$v.form.role.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.role) errors.push(this.apiErrors.role);
        return errors;
      }
    },

    created() {
      const userId = this.$route.params.userId
      if (userId) {
        if (this.userData) {
          this.form.email = this.userData.email;
          this.form.role = this.userData.role;
        } else {
          this.loadingUser = true;
          this.getUser(userId);
        }
      }
    },

    methods: {
      getUser(userId) {
        userService.getSingle(userId).then(data => {
          if (data.success) {
            this.form.email = data.data.user.email;
            this.form.role = data.data.user.role;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingUser = false;
        });
      },

      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;

          if (this.$route.params.userId) {
            this.updateUser();
          } else {
            this.createUser();
          }
        }
      },

      createUser() {
        userService.create(this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      updateUser() {
        const userId = this.$route.params.userId;
        userService.update(userId, this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      closeDialog() {
        this.dialog = false;
        this.$emit('close-dialog');
        this.$router.push({ name: 'adminUsers' })
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      }
    }
  }
</script>