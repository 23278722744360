<template>

  <v-container fluid>
    <div class="header mb-6">
      <v-row align="center">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="overline pa-0"></v-breadcrumbs>
          <h1 class="header__title font-weight-medium mt-0 mb-0">Lista użytkowników</h1>
        </v-col>
        <v-col cols="auto">
          <v-btn large class="elevation-0 white--text font-weight-medium" color="#008000" to="/admin/users/create" :disabled="loadingUsers">Dodaj użytkownika</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-card elevation="0">
      <v-card-title class="py-3 px-6">
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            label="Szukaj"
            solo
            clearable
            class="input--search"
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
            :headers="headers"
            :items="usersList"
            :sort-desc="false"
            :loading="loadingUsers"
            :search="search"
            sort-by="id"
            class="table--datatable elevation-0"
            no-data-text="Nic nie znaleziono"
            no-results-text="Nie znaleziono wyników pasujących do kryteriów wyszukiwania"
            loading-text="Ładowanie..."
            :header-props="{
              sortByText: 'Sortuj po:'
            }"
            :footer-props="{
              itemsPerPageAllText: 'Wszystkie',
              itemsPerPageText: 'Ilość wyników na stronie'
            }"
        >
          <template v-slot:[`item.role`]="{ item }">
            <v-chip label small :color="getColor(item.role)" class="text-uppercase" :class="{ 'white--text': item.role == 'admin' }">
              {{ item.role }}
            </v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn elevation="0" class="white--text ma-1" color="#008000" @click="showUserDialog(item)">Edytuj</v-btn>
            <v-btn color="error" elevation="0" class="ma-1" @click="showDeleteDialog(item)">Usuń</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <router-view name="adminUserDialog" :userData="userData" v-on:close-dialog="closeUserDialog" v-on:dialog-callback="getUsers"></router-view>
  </v-container>

</template>

<script>

  import userService from '../../services/admin.user.service';

  export default {
    name: 'AdminUsers',

    data: () => ({
      breadcrumbsItems: [
        { text: 'Użytkownicy' }
      ],
      usersList: [],
      search: '',
      headers: [
        { text: 'E-mail', value: 'email' },
        { text: 'Rola', value: 'role', sortable: false },
        { text: '', value: 'actions', align: 'end', sortable: false }
      ],
      loadingUsers: true,
      userData: null,
      deleteDialogData: null,
      loadingDeleteDialog: false
    }),

    created() {
      this.getUsers();
    },

    methods: {
      getUsers() {
        this.loadingUsers = true;

        userService.getList().then(data => {
          if (data.success) {
            this.usersList = data.data.users;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingUsers = false;
        });
      },

      showUserDialog(user) {
        this.userData = user;
        this.$router.push({ name: 'adminUserEdit', params: { userId: user.id } });
      },

      closeUserDialog() {
        this.userData = null;
      },

      showDeleteDialog(user) {
        this.deleteDialogData = {
          userId: user.id,
          title: 'Usuwanie użytkownika',
          text: 'Na pewno usunąć użytkownika?'
        };
        this.$router.push({ name: 'adminUserDelete', params: { userId: user.id } });
      },

      closeDeleteDialog() {
        this.deleteDialogData = null;
        this.$router.push({ name: 'adminUsers' })
      },

      deleteUser() {
        this.loadingDeleteDialog = true;

        userService.delete(this.deleteDialogData.userId).then(data => {
          if (data.success) {
            this.closeDeleteDialog();
            this.getUsers();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDeleteDialog = false;
        });
      },

      getColor(role) {
        if (role == 'admin') return 'red'
        if (role == 'analyst') return '#fff886'
        if (role == 'agent') return '#f9b7ab'
        if (role == 'landlord') return '#7dd2bf'
        else return '#bae297'
      },
    }
  }

</script>