import axios from 'axios';
import authHeader from "./auth-header";

const apiUrl = 'https://zl-api.mma.unixstorm.eu/user/discounts';

export default {

    checkCode(formData) {
        return axios.post(apiUrl + '/check', {
            package:    formData.package,
            code:       formData.code
        }, { headers: authHeader() }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: "Błąd połączenia z API."
            };
            return response;
        });
    },

}