<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <form @submit.prevent="submitForm">
      <v-card class="elevation-0">
        <v-card-title class="justify-space-between pa-6">
          <span class="headline">Kod rabatowy</span>
          <v-btn text icon :disabled="loadingForm" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-6 py-0">
          <v-row>
            <v-col cols="12">
              <p class="mt-0 mb-2">Wybierz pakiety, dla których ma działać kod:</p>
              <v-checkbox
                  v-model="form.basic"
                  :disabled="loadingForm"
                  value="1"
                  label="Basic"
                  hide-details="auto"
                  class="pt-0 mt-0"
              ></v-checkbox>

              <v-checkbox
                  v-model="form.standard"
                  :disabled="loadingForm"
                  value="1"
                  label="Standard"
                  hide-details="auto"
                  class="pt-0 mt-0"
              ></v-checkbox>

              <v-checkbox
                  v-model="form.premium"
                  :disabled="loadingForm"
                  value="1"
                  label="Premium"
                  hide-details="auto"
                  class="pt-0 mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="form.code"
                  :disabled="loadingForm"
                  :error-messages="formCodeErrors"
                  label="Kod"
                  hide-details="auto"
                  outlined
                  @input="$v.form.code.$touch(), removeApiError('code')"
                  @blur="$v.form.code.$touch(), removeApiError('code')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex">
              <v-text-field
                  v-model="form.discount"
                  :disabled="loadingForm"
                  :error-messages="formDiscountErrors"
                  label="Rabat"
                  hide-details="auto"
                  outlined
                  @input="$v.form.discount.$touch(), removeApiError('discount')"
                  @blur="$v.form.discount.$touch(), removeApiError('discount')"
                  style="width: 100%;"
              >
              </v-text-field>
              <v-select
                  v-model="form.discountType"
                  :disabled="loadingForm"
                  :items="['%', 'PLN']"
                  class="select--discount-options ml-2"
                  hide-details="auto"
                  outlined
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                  v-model="form.limitedQuantity"
                  :disabled="loadingForm"
                  value="1"
                  label="Kod ograniczony ilościowo"
                  hide-details="auto"
                  class="pt-0 mt-0"
              ></v-checkbox>

              <v-text-field
                  v-model="form.quantity"
                  :disabled="loadingForm"
                  :error-messages="formQuantityErrors"
                  label="Ilość"
                  hide-details="auto"
                  outlined
                  @input="$v.form.quantity.$touch(), removeApiError('quantity')"
                  @blur="$v.form.quantity.$touch(), removeApiError('quantity')"
                  class="mt-3"
                  v-if="form.limitedQuantity == '1'"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                  v-model="form.limitedTime"
                  :disabled="loadingForm"
                  value="1"
                  label="Kod ograniczony czasowo"
                  hide-details="auto"
                  class="pt-0 mt-0"
              ></v-checkbox>

              <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-if="form.limitedTime == '1'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="form.date"
                      :disabled="loadingForm"
                      :error-messages="formDateErrors"
                      label="Data"
                      hide-details="auto"
                      outlined
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @input="$v.form.date.$touch(), removeApiError('date')"
                      @blur="$v.form.date.$touch(), removeApiError('date')"
                      class="mt-3"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="form.date"
                    locale="pl"
                    no-title
                    @input="menuDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn type="button" class="elevation-0" large text :disabled="loadingForm" @click="closeDialog">Anuluj</v-btn>
          <v-btn type="submit" class="elevation-0 white--text font-weight-medium" color="#008000" large :disabled="loadingForm" :loading="loadingForm">Zapisz</v-btn>
        </v-card-actions>

        <v-overlay absolute :value="loadingDiscount" color="#ffffff">
          <v-progress-circular
              indeterminate
              color="primary"
              size="46"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required, requiredIf, integer } from 'vuelidate/lib/validators';
  import discountService from '../../services/admin.discount.service';

  export default {
    name: 'DiscountDialog',

    props: ['discountData'],

    mixins: [validationMixin],

    validations: {
      form: {
        code: {
          required
        },
        discount: {
          required,
          integer,
          isValid(value) {
            if (this.form.discountType == '%') {
              return value > 0 && value <= 100;
            }
            return true;
          }
        },
        quantity: {
          required: requiredIf(function() {
            return this.form.limitedQuantity == '1'
          }),
          integer
        },
        date: {
          required: requiredIf(function() {
            return this.form.limitedTime == '1'
          }),
        }
      }
    },

    data: () => ({
      dialog: true,
      form: {
        basic: '1',
        standard: '1',
        premium: '1',
        code: '',
        discount: '',
        discountType: '%',
        limitedQuantity: '0',
        quantity: '',
        limitedTime: '0',
        date: ''
      },
      loadingDiscount: false,
      loadingForm: false,
      apiErrors: null,
      menuDate: false
    }),

    computed: {
      formCodeErrors() {
        const errors = [];
        if (!this.$v.form.code.$dirty) return errors;
        !this.$v.form.code.required && errors.push('Musisz uzupełnić to pole.');
        if (this.apiErrors && this.apiErrors.code) errors.push(this.apiErrors.code);
        return errors;
      },

      formDiscountErrors() {
        const errors = [];
        if (!this.$v.form.discount.$dirty) return errors;
        !this.$v.form.discount.required && errors.push('Musisz uzupełnić to pole.');
        !this.$v.form.discount.integer && errors.push('Nieprawidłowa wartość.');
        !this.$v.form.discount.isValid && errors.push('Wartość musi być z przedziału 1 - 100.');
        if (this.apiErrors && this.apiErrors.discount) errors.push(this.apiErrors.discount);
        return errors;
      },

      formQuantityErrors() {
        const errors = [];
        if (!this.$v.form.quantity.$dirty) return errors;
        !this.$v.form.quantity.required && errors.push('Musisz uzupełnić to pole.');
        !this.$v.form.quantity.integer && errors.push('Nieprawidłowa wartość.');
        if (this.apiErrors && this.apiErrors.quantity) errors.push(this.apiErrors.quantity);
        return errors;
      },

      formDateErrors() {
        const errors = [];
        if (!this.$v.form.date.$dirty) return errors;
        !this.$v.form.date.required && errors.push('Musisz uzupełnić to pole.');
        if (this.apiErrors && this.apiErrors.date) errors.push(this.apiErrors.date);
        return errors;
      }
    },

    created() {
      const discountId = this.$route.params.discountId
      if (discountId) {
        if (this.discountData) {
          this.form.basic = this.discountData.basic;
          this.form.standard = this.discountData.standard;
          this.form.premium = this.discountData.premium;
          this.form.code = this.discountData.code;
          this.form.discount = this.discountData.discount;
          this.form.discountType = this.discountData.discountType;
          this.form.limitedQuantity = this.discountData.limitedQuantity;
          this.form.quantity = this.discountData.limitedQuantity == 1 ? this.discountData.quantity : '';
          this.form.limitedTime = this.discountData.limitedTime;
          this.form.date = this.discountData.limitedTime == 1 ? this.discountData.date : '';
        } else {
          this.loadingDiscount = true;
          this.getDiscount(discountId);
        }
      }
    },

    methods: {
      getDiscount(discountId) {
        discountService.getSingle(discountId).then(data => {
          if (data.success) {
            this.form.basic = data.data.discount.basic;
            this.form.standard = data.data.discount.standard;
            this.form.premium = data.data.discount.premium;
            this.form.code = data.data.discount.code;
            this.form.discount = data.data.discount.discount;
            this.form.discountType = data.data.discount.discountType;
            this.form.limitedQuantity = data.data.discount.limitedQuantity;
            this.form.quantity = data.data.discount.limitedQuantity == 1 ? data.data.discount.quantity : '';
            this.form.limitedTime = data.data.discount.limitedTime;
            this.form.date = data.data.discount.limitedTime == 1 ? data.data.discount.date : '';
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingDiscount = false;
        });
      },

      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;

          if (this.$route.params.discountId) {
            this.updateDiscount();
          } else {
            this.createDiscount();
          }
        }
      },

      createDiscount() {
        discountService.create(this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      updateDiscount() {
        const discountId = this.$route.params.discountId;
        discountService.update(discountId, this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      closeDialog() {
        this.dialog = false;
        this.$emit('close-dialog');
        this.$router.push({ name: 'adminDiscounts' })
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      }
    }
  }
</script>

<style>
.select--discount-options { flex: 1; }
</style>