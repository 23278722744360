<template>

  <v-container fluid fill-height>
    <v-row justify="center">
      <v-col cols="12" class="d-flex justify-center">
        <form @submit.prevent="submitForm">
          <v-card elevation="0" max-width="500">
            <v-card-text class="px-6 pt-6 pb-0">
              <v-row>
                <v-col cols="12">
                  <v-img
                      alt="Zaufany Lokator"
                      contain
                      height="80"
                      class="mb-2"
                      src="../assets/logo-zaufany-lokator.svg"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="form.email"
                      :disabled="loadingForm"
                      :error-messages="formEmailErrors"
                      label="E-mail"
                      hide-details="auto"
                      outlined
                      @input="$v.form.email.$touch(), removeApiError('email')"
                      @blur="$v.form.email.$touch(), removeApiError('email')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="form.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :disabled="loadingForm"
                      :error-messages="formPasswordErrors"
                      :type="showPassword ? 'text' : 'password'"
                      label="Hasło"
                      hide-details="auto"
                      outlined
                      @input="$v.form.password.$touch(), removeApiError('password'), checkPassword()"
                      @blur="$v.form.password.$touch(), removeApiError('password'), checkPassword()"
                      @click:append="showPassword = !showPassword"
                  ></v-text-field>

                  <ul v-if="passInput && (!passHasMinLength || !passContainsLowercase || !passContainsUppercase || !passContainsNumber || !passContainsSpecial)" class="mt-3">
                    <li :class="{ 'error--text': passInput && !passHasMinLength, 'success--text': passInput && passHasMinLength }">Hasło musi mieć co najmniej 8 znaków</li>
                    <li :class="{ 'error--text': passInput && !passContainsLowercase, 'success--text': passInput && passContainsLowercase }">Hasło musi zawierać co najmniej 1 małą literę</li>
                    <li :class="{ 'error--text': passInput && !passContainsUppercase, 'success--text': passInput && passContainsUppercase }">Hasło musi zawierać co najmniej 1 dużą literę</li>
                    <li :class="{ 'error--text': passInput && !passContainsNumber, 'success--text': passInput && passContainsNumber }">Hasło musi zawierać co najmniej 1 cyfrę</li>
                    <li :class="{ 'error--text': passInput && !passContainsSpecial, 'success--text': passInput && passContainsSpecial }">Hasło musi zawierać co najmniej 1 znak specjalny (#, ?, !, @, $, %, ^, &, *)</li>
                  </ul>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="d-flex flex-column pa-6">
              <v-btn type="submit" class="elevation-0 white--text font-weight-medium" color="#008000" large block :disabled="loadingForm" :loading="loadingForm">Załóż konto</v-btn>
              <v-btn text to="/login" class="mt-3" :disabled="loadingForm">Wróć do logowania</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required, email } from 'vuelidate/lib/validators';
  import accountService from "@/services/account.service";

  export default {
    name: 'SignUp',

    mixins: [validationMixin],

    validations: {
      form: {
        email: {
          required,
          email
        },
        password: {
          required,
          passwordStrength: value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/.test(value),
        }
      }
    },

    data: () => ({
      form: {
        email: '',
        password: ''
      },
      passInput: false,
      passHasMinLength: false,
      passContainsUppercase: false,
      passContainsLowercase: false,
      passContainsNumber: false,
      passContainsSpecial: false,
      showPassword: false,
      loadingForm: false,
      apiErrors: null
    }),

    computed: {
      formEmailErrors() {
        const errors = [];
        if (!this.$v.form.email.$dirty) return errors;
        !this.$v.form.email.required && errors.push('Musisz uzupełnić to pole.');
        !this.$v.form.email.email && errors.push('Adres e-mail jest nieprawidłowy.');
        if (this.apiErrors && this.apiErrors.email) errors.push(this.apiErrors.email);
        return errors;
      },

      formPasswordErrors() {
        const errors = [];
        if (!this.$v.form.password.$dirty) return errors;
        !this.$v.form.password.required && errors.push('Musisz uzupełnić to pole.');
        !this.$v.form.password.passwordStrength && errors.push('Hasło nie spełnia wymogów bezpieczeństwa.');
        if (this.apiErrors && this.apiErrors.password) errors.push(this.apiErrors.password);
        return errors;
      }
    },

    methods: {
      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;
          this.signUp();
        }
      },

      signUp() {
        accountService.signUp(this.form).then(data => {
          if (data.success) {
            this.$router.push({ name: "login" });
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      },

      checkPassword() {
        this.passInput = true;

        this.form.password.length >= 8 ? this.passHasMinLength = true : this.passHasMinLength = false;
        /[A-Z]/.test(this.form.password) ? this.passContainsUppercase = true : this.passContainsUppercase = false;
        /[a-z]/.test(this.form.password) ? this.passContainsLowercase = true : this.passContainsLowercase = false;
        /[0-9]/.test(this.form.password) ? this.passContainsNumber = true : this.passContainsNumber = false;
        /[#?!@$%^&*-]/.test(this.form.password) ? this.passContainsSpecial = true : this.passContainsSpecial = false;
      }
    }
  };
</script>

<style>

</style>