import axios from 'axios';

const apiUrl = 'https://zl-api.mma.unixstorm.eu/account';

export default {

    login(userData) {
        return axios.post(apiUrl + '/login', {
            email:      userData.email,
            password:   userData.password
        }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: 'Błąd połączenia z API.'
            };
            return response;
        });
    },

    loginAuth(userData) {
        return axios.post(apiUrl + '/login/auth', {
            email:  userData.email,
            code:   userData.code,
        }).then(response => {
            if (response.data.success) {
                sessionStorage.setItem("token", response.data.data.token);
            }

            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: 'Błąd połączenia z API.'
            };
            return response;
        });
    },

    sendAuthCode(email) {
        return axios.post(apiUrl + '/login/auth/send-code', {
            email: email
        }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: 'Błąd połączenia z API.'
            };
            return response;
        });
    },

    logout() {
        sessionStorage.removeItem('token');
    },

    forgotPassword(userData) {
        return axios.post(apiUrl + '/forgot-password', {
            email:  userData.email
        }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: 'Błąd połączenia z API.'
            };
            return response;
        });
    },

    resetPassword(resetPasswordCode, userData) {
        return axios.post(apiUrl + '/reset-password/' + resetPasswordCode, {
            password:           userData.password,
            passwordRepeat:     userData.passwordRepeat
        }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: 'Błąd połączenia z API.'
            };
            return response;
        });
    },

    signUp(userData) {
        return axios.post(apiUrl + '/signup', {
            email:      userData.email,
            password:   userData.password
        }).then(response => {
            return response.data;
        }).catch(function() {
            const response = {
                success: false,
                message: 'Błąd połączenia z API.'
            };
            return response;
        });
    }
}