<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <form @submit.prevent="submitForm">
      <v-card class="elevation-0">
        <v-card-title class="justify-space-between pa-6">
          <span class="headline">Pytanie</span>
          <v-btn text icon :disabled="loadingForm" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-6 py-0">
          <v-row>
            <v-col cols="12">
              <v-select
                  :items="types"
                  item-text="text"
                  item-value="value"
                  v-model="form.type"
                  :disabled="loadingForm"
                  :error-messages="formTypeErrors"
                  label="Typ"
                  hide-details="auto"
                  outlined
                  @change="$v.form.type.$touch(), removeApiError('type')"
                  @blur="$v.form.type.$touch(), removeApiError('type')"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="form.question"
                  :disabled="loadingForm"
                  :error-messages="formQuestionErrors"
                  label="Pytanie"
                  hide-details="auto"
                  outlined
                  @input="$v.form.question.$touch(), removeApiError('question')"
                  @blur="$v.form.question.$touch(), removeApiError('question')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                  v-model="form.isRequired"
                  value="1"
                  label="Odpowiedź jest wymagana"
                  hide-details="auto"
                  class="pt-0 mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn type="button" class="elevation-0" large text :disabled="loadingForm" @click="closeDialog">Anuluj</v-btn>
          <v-btn type="submit" class="elevation-0 white--text font-weight-medium" color="#008000" large :disabled="loadingForm" :loading="loadingForm">Zapisz</v-btn>
        </v-card-actions>

        <v-overlay absolute :value="loadingInterviewQuestion" color="#ffffff">
          <v-progress-circular
              indeterminate
              color="primary"
              size="46"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import interviewQuestionService from '../../services/admin.interviewQuestion.service';

  export default {
    name: 'InterviewQuestionDialog',

    props: ['interviewQuestionData'],

    mixins: [validationMixin],

    validations: {
      form: {
        type: {
          required
        },
        question: {
          required
        }
      }
    },

    data: () => ({
      dialog: true,
      form: {
        question: '',
        type: null,
        isRequired: 0
      },
      types: [
        { text: 'Input', value: 'input' },
        { text: 'Textarea', value: 'textarea' },
        { text: 'Select', value: 'select' },
        { text: 'Radio', value: 'radio' },
        { text: 'Checkbox', value: 'checkbox' },
        { text: 'File', value: 'file' }
      ],
      loadingInterviewQuestion: false,
      loadingForm: false,
      apiErrors: null
    }),

    computed: {
      formTypeErrors() {
        const errors = [];
        if (!this.$v.form.type.$dirty) return errors;
        !this.$v.form.type.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.type) errors.push(this.apiErrors.type);
        return errors;
      },
      formQuestionErrors() {
        const errors = [];
        if (!this.$v.form.question.$dirty) return errors;
        !this.$v.form.question.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.question) errors.push(this.apiErrors.question);
        return errors;
      }
    },

    created() {
      const interviewQuestionId = this.$route.params.interviewQuestionId
      if (interviewQuestionId) {
        if (this.interviewQuestionData) {
          this.form.type = this.interviewQuestionData.type;
          this.form.question = this.interviewQuestionData.question;
          this.form.isRequired = this.interviewQuestionData.isRequired;
        } else {
          this.loadingInterviewQuestion = true;
          this.getInterviewQuestion(interviewQuestionId);
        }
      }
    },

    methods: {
      getInterviewQuestion(interviewQuestionId) {
        interviewQuestionService.getSingle(interviewQuestionId).then(data => {
          if (data.success) {
            this.form.type = data.data.interviewQuestion.type;
            this.form.question = data.data.interviewQuestion.question;
            this.form.isRequired = data.data.interviewQuestion.isRequired;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingInterviewQuestion = false;
        });
      },

      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;

          if (this.$route.params.interviewQuestionId) {
            this.updateInterviewQuestion();
          } else {
            this.createInterviewQuestion();
          }
        }
      },

      createInterviewQuestion() {
        interviewQuestionService.create(this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      updateInterviewQuestion() {
        const interviewQuestionId = this.$route.params.interviewQuestionId;
        interviewQuestionService.update(interviewQuestionId, this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      closeDialog() {
        this.dialog = false;
        this.$emit('close-dialog');
        this.$router.push({ name: 'adminInterview' })
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      }
    }
  }
</script>