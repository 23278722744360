import jwt_decode from "jwt-decode";
import accountService from "../services/account.service";

const token = sessionStorage.getItem("token");
const user = token ? jwt_decode(token) : null;
const state = token ? { loggedIn: true, user } : { loggedIn: false, user }

const getters = {

}

const actions = {
    login({ commit }, userData) {
        return accountService.loginAuth(userData).then(
            data => {
                if (data.success) {
                    const user = jwt_decode(data.data.token);
                    commit("loginSuccess", user);
                } else {
                    commit("loginFailure");
                }

                return data;
            }
        );
    },

    logout({ commit }) {
        accountService.logout();
        commit("logout");
    },
}

const mutations = {
    loginSuccess(state, user) {
        state.loggedIn = true;
        state.user = user;
    },

    loginFailure(state) {
        state.loggedIn = false;
        state.user = null;
    },

    logout(state) {
        state.loggedIn = false;
        state.user = null;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}