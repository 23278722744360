<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <v-card class="elevation-0">
      <v-card-title class="justify-space-between pa-6">
        <span class="headline">{{ title }}</span>
        <v-btn text icon :disabled="loading" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-6 py-0">
        <span v-html="text"></span>
      </v-card-text>

      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn type="button" class="elevation-0" large text :disabled="loading" @click="closeDialog">Anuluj</v-btn>
        <v-btn type="button" class="elevation-0" color="error" large :disabled="loading" :loading="loading" @click="$emit('dialog-callback')">Usuń</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "DeleteDialog",

    props: ["dialogData", "loading"],

    data: () => ({
      dialog: true,
      title: "",
      text: ""
    }),

    created() {
      if (this.dialogData) {
        this.title = this.dialogData.title;
        this.text = this.dialogData.text;
      } else {
        this.closeDialog();
      }
    },

    methods: {
      closeDialog() {
        this.dialog = false;
        this.$emit("close-dialog");
      }
    }
  };
</script>