<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
  >
    <form @submit.prevent="submitForm">
      <v-card class="elevation-0">
        <v-card-title class="justify-space-between pa-6">
          <span class="headline">Odpowiedź</span>
          <v-btn text icon :disabled="loadingForm" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-6 py-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  :value="interviewQuestion"
                  disabled
                  label="Pytanie"
                  hide-details="auto"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="form.answer"
                  :disabled="loadingForm"
                  :error-messages="formAnswerErrors"
                  label="Odpowiedź"
                  hide-details="auto"
                  outlined
                  @input="$v.form.answer.$touch(), removeApiError('answer')"
                  @blur="$v.form.answer.$touch(), removeApiError('answer')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn type="button" class="elevation-0" large text :disabled="loadingForm" @click="closeDialog">Anuluj</v-btn>
          <v-btn type="submit" class="elevation-0 white--text font-weight-medium" color="#008000" large :disabled="loadingForm" :loading="loadingForm">Zapisz</v-btn>
        </v-card-actions>

        <v-overlay absolute :value="loadingInterviewQuestion || loadingInterviewAnswer" color="#ffffff">
          <v-progress-circular
              indeterminate
              color="primary"
              size="46"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import interviewAnswerService from '../../services/admin.interviewAnswer.service';
  import interviewQuestionService from "@/services/admin.interviewQuestion.service";

  export default {
    name: 'InterviewAnswerDialog',

    props: ['interviewQuestionData', 'interviewAnswerData'],

    mixins: [validationMixin],

    validations: {
      form: {
        answer: {
          required
        }
      }
    },

    data: () => ({
      dialog: true,
      form: {
        answer: ''
      },
      interviewQuestion: '',
      loadingInterviewQuestion: false,
      loadingInterviewAnswer: false,
      loadingForm: false,
      apiErrors: null
    }),

    computed: {
      formAnswerErrors() {
        const errors = [];
        if (!this.$v.form.answer.$dirty) return errors;
        !this.$v.form.answer.required && errors.push('Musisz uzupełnić to pole');
        if (this.apiErrors && this.apiErrors.answer) errors.push(this.apiErrors.answer);
        return errors;
      }
    },

    created() {
      const interviewQuestionId = this.$route.params.interviewQuestionId;
      if (this.interviewQuestionData) {
        this.interviewQuestion = this.interviewQuestionData.question;
      } else {
        this.loadingInterviewQuestion = true;
        this.getInterviewQuestion(interviewQuestionId);
      }

      const interviewAnswerId = this.$route.params.interviewAnswerId;
      if (interviewAnswerId) {
        if (this.interviewAnswerData) {
          this.form.answer = this.interviewAnswerData.answer;
        } else {
          this.loadingInterviewAnswer = true;
          this.getInterviewAnswer(interviewQuestionId, interviewAnswerId);
        }
      }
    },

    methods: {
      getInterviewQuestion(interviewQuestionId) {
        interviewQuestionService.getSingle(interviewQuestionId).then(data => {
          if (data.success) {
            this.interviewQuestion = data.data.interviewQuestion.question;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({name: 'login'});
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingInterviewQuestion = false;
        });
      },

      getInterviewAnswer(interviewQuestionId, interviewAnswerId) {
        interviewAnswerService.getSingle(interviewQuestionId, interviewAnswerId).then(data => {
          if (data.success) {
            this.form.answer = data.data.interviewAnswer.answer;
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingInterviewAnswer = false;
        });
      },

      submitForm() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.apiErrors = null;
          this.loadingForm = true;

          if (this.$route.params.interviewAnswerId) {
            this.updateInterviewAnswer();
          } else {
            this.createInterviewAnswer();
          }
        }
      },

      createInterviewAnswer() {
        const interviewQuestionId = this.$route.params.interviewQuestionId;
        interviewAnswerService.create(this.form, interviewQuestionId).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      updateInterviewAnswer() {
        const interviewAnswerId = this.$route.params.interviewAnswerId;
        const interviewQuestionId = this.$route.params.interviewQuestionId;

        interviewAnswerService.update(interviewQuestionId, interviewAnswerId, this.form).then(data => {
          if (data.success) {
            this.$emit('dialog-callback');
            this.closeDialog();
          } else {
            if (data.status == 401 || data.status == 403) {
              this.$store.dispatch('account/logout');
              this.$router.push({ name: 'login' });
            }

            this.apiErrors = data.errors;
          }

          if (data.snackbar) {
            this.$store.commit('snackbar/addObject', {
              message: data.message,
              color: data.snackbar.color
            });
          }

          this.loadingForm = false;
        });
      },

      closeDialog() {
        this.dialog = false;
        this.$emit('close-dialog');
        this.$router.push({ name: 'adminInterview' })
      },

      removeApiError(propertyName) {
        if (this.apiErrors && this.apiErrors[propertyName]) {
          this.apiErrors[propertyName] = null;
        }
      }
    }
  }
</script>